<template>
  <div class="home">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "emptyLayout",
  data() {
    return {
    };
  },
  computed:{
  },
  mounted(){
  },
  methods:{

  }
};
</script>

